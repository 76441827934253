import React from 'react';
import './Projects.css';
import '../../assets/css/style.css';
import Header from '../../components/HeaderComponent/Header';
import Footer from '../../components/FooterComponent/Footer';

import ademinsaat from './img/ademinsaat.jpg'
import setkaya from './img/setkaya.jpg'
import oilstart from './img/oilstart.jpeg'
// import febgroup from './img/febgroup.jpg'
// import smartcafe from './img/smartcafe.jpg'
import avto103 from './img/avto103.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
function Projects() {
    return (
        <>
            <div className='container'>
                <Header />
                <div className='projects-container'>
                    <div className='project-header'>
                        <p>Our <span>Projects</span></p>
                        <p></p>
                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={oilstart} alt="" />
                        </div>
                        <div>
                            <p>Oil Star</p>
                            <p>Since 2017, Oil Star has been delivering exceptional services in Azerbaijan's oil and gas sector. Leveraging cutting-edge technology and advanced processes, we provide innovative solutions tailored to our clients' evolving needs. Our focus on safety, reliability, and sustainability ensures continuous improvement and excellence in every operation. In 2024, they worked with us to reach out new customers through internet. </p>
                            <a target='_blank'  rel="noopener noreferrer" href="https://oilstar.az/">

                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                            </a>
                        </div>


                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={setkaya} alt="" />
                        </div>
                        <div>
                            <p>SETKAYA MMC</p>
                            <p>SETKAYA MMC is a professional and experienced company, offering high-quality solutions for modern industrial and sports sectors. Utilizing advanced technologies, the company specializes in artificial turf, synthetic surfaces, and installation services and durability for both indoor and outdoor spaces. They recently entered into the new market and decided to work with us for website development and social media marketing. </p>
                            <a target='_blank'  rel="noopener noreferrer" href="https://setkaya.az/">

                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                            </a>
                        </div>


                    </div>
                    {/* <div className='projects-headline'>
                        <div>
                            <img src={smartcafe} alt="" />
                        </div>
                        <div>
                            <p>Smartcafe</p>
                            <p>We create websites not only for businesses aiming to reach new audiences but also for those seeking to manage operations effectively. Smartcafe is a website designed specifically for cafe management. </p>
                            <a target='_blank'  rel="noopener noreferrer" href="https://smartcafe.az/">

                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                            </a>
                        </div>


                    </div> */}

                    <div className='projects-headline'>
                        <div>
                            <img src={ademinsaat} alt="" />
                        </div>
                        <div>
                            <p>Ademinshaat</p>
                            <p>Ademinshaat specializing in the wholesale trade of construction materials focuses on providing high-quality products to meet the expectations. By sourcing from reliable manufacturers, the company ensures competitive pricing and consistent supply. They made first steps into the digital world with our simple and functional website. </p>
                          <a target='_blank'  rel="noopener noreferrer" href="https://ademinshaat.az/">

                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                          </a>
                        </div>


                    </div>
                    {/* <div className='projects-headline'>
                        <div>
                            <img src={febgroup} alt="" />
                        </div>
                        <div>
                            <p>Febgroup</p>
                            <p>Febgroup stands out as a top choice in its sector, thanks to a business model built on the latest technologies. By prioritizing sales and after-sales services, strong communication, and attention to partners, the company has established lasting collaborations with leading restaurants and hotels. Restaurants were dealing with their marketing strategy. They wanted to rise level up and our service, helped them to target more groups with stylish website.</p>
                            <a target='_blank'  rel="noopener noreferrer" href="https://febgroup.az/">
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                            </a>
                        </div>


                    </div> */}
                    <div className='projects-headline'>
                        <div>
                            <img src={avto103} alt="" />
                        </div>
                        <div>
                            <p>Rent a car </p>
                            <p>This website is designed for rent a car company. As you see, we have customers from different sectors and we can deliver any kind of website with required design and features.</p>
                            <a target='_blank'  rel="noopener noreferrer" href="https://avto-103.com/">
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                            </a>
                        </div>


                    </div>

                </div>

                <Footer />
            </div>


        </>
    )
}

export default Projects
